@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

*{
    margin:0;
    padding:0;
    font-family: 'Lato', sans-serif;
}
.custom-menu-list {
    
    right:-24vw
  }


  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  
  
  
  
  
  
  
