@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
* {
  font-family: "Lato", sans-serif;
}

.satisfy-regular {
  font-family: "Satisfy", cursive;
  font-weight: 800;
  font-style: normal;
}

.banner {
  background-image: url("./doctor_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.universities {
  background-image: url("./Rectangle\ 42.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel .control-dots .dot {
  background-color: blue !important;
}

.carousel .control-dots .dot:hover,
.carousel .control-dots .dot.selected {
  background-color: blue !important;
}

/* .container16{
    position:relative;
  } */
/* .container16::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 85vh;
    background-image: url('./what\ our\ student\ says.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:white;
    z-index: -1;
  
  } */

@media (max-width: 600px) {
  /* Styles for screens with a width of 600px or less */
  .container16::before {
    background-image: none;
  }
}

.five-star-rating {
  font-size: 0; /* Remove white space between inline-block elements */
  margin-top: 1vh;
}

.star {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 2px;
  background-color: transparent;
  clip-path: polygon(
    50% 0%,
    61% 38%,
    100% 38%,
    68% 59%,
    79% 100%,
    50% 75%,
    21% 100%,
    32% 59%,
    0% 38%,
    39% 38%
  );
}

.yellow-star {
  background-color: #023b7d;
}

.container11 {
  position: relative;
  z-index: -1;
}
.container11::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./blogbanner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: white;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.container12 {
  background-image: url("./blog1.jpg");
  background-size: cover;
  background-position: center;
}

.container13 {
  background-image: url("./blog2.jpg");
  background-size: cover;
  background-position: center;
}

.container14 {
  background-image: url("./blog3.jpg");
  background-size: cover;
  background-position: center;
}

.container15 {
  background-image: url("./blog4.jpg");
  background-size: cover;
  background-position: center;
}

.search-bars2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4vh;
}

.search-bar2 {
  height: 5vh;
  width: 40vw;
  padding: 10px;
  margin: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none; /* Remove default outline */
}

.search-bar3 {
  height: 12vh;
  width: 40vw;
  padding: 10px;
  margin: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none; /* Remove default outline */
}

@media (max-width: 600px) {
  .search-bars2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vh;
  }

  .search-bar2 {
    height: 5vh;
    width: 90vw;
    padding: 10px;
    margin: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none; /* Remove default outline */
  }

  .search-bar3 {
    height: 12vh;
    width: 90vw;
    padding: 10px;
    margin: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none; /* Remove default outline */
  }
}

@media (max-width: 900px) {
  .banner {
    background-color: #1c375b;
    background-image: none;
  }
}
