
.custom2-background{
    position: relative;
    width: 100vw;
    height: 66vh;
   
      
  }
  .custom2-background::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./Kyrgyzstan1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:rgba(0, 0,0,0.75);
    
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  